import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Video } from "../../../constants";
import ComponentWow from "../../Wow";

interface IAbout {
  onPlay: () => void;
  isAbout?: boolean;
}

const About: React.FC<IAbout> = ({ onPlay, isAbout = false }) => {
  const { t } = useTranslation();

  const renderTitle = () => {
    if (isAbout)
      return (
        <h1 className="h1 mb-4 section-title">{t("homepage.about.title")}</h1>
      );
    return (
      <h3 className="h1 mb-4 section-title">{t("homepage.about.title")}</h3>
    );
  };

  return (
    <ComponentWow>
      <div className="container-fluid py-5 mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="d-flex flex-column align-items-center justify-content-center bg-about rounded h-100 py-5 px-3">
                <i className="fa fa-5x fa-award text-primary mb-4"></i>
                
                <h2 className="text-white m-0 text-center">
                  {t("homepage.about.countryServed")}
                </h2>
              </div>
            </div>
            <div className="col-lg-7 pt-5 pb-lg-5">
              <h6 className="text-primary font-weight-semi-bold text-uppercase mb-3">
                {t("homepage.about.learnUs")}
              </h6>
              {renderTitle()}

              <p>{t("homepage.about.desc_1")}</p>
              <p>{t("homepage.about.desc_2")}</p>
              <div className="d-flex align-items-center pt-4">
                <a
                  href=""
                  data-toggle="modal"
                  data-target="#getAQuote"
                  className="btn btn-primary mr-5"
                >
                  {t("getAQuote")}
                </a>
                <button
                  type="button"
                  className="btn-play"
                  data-toggle="modal"
                  src={Video.aboutUs}
                  data-target="#videoModal"
                >
                  <span></span>
                </button>
                <h5
                  className="font-weight-normal text-white m-0 ml-4 d-none d-sm-block"
                  onClick={onPlay}
                >
                  Play Video
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ComponentWow>
  );
};

export default About;
