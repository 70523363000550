import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

const VisionAndMission = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid py-5">
      <h2 className="text-secondary font-weight-semi-bold text-uppercase mb-3 text-center">
        {t("vision-mission")}
      </h2>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <div className="visionMissionWrapper">
              <div style={{
                paddingTop: '5px'
              }}>
                <div className="dotsMisionVision"></div>
              </div>

              <div
                style={{
                  marginLeft: "20px",
                }}
              >
                <h4>{t("vision.title")}</h4>
                <p>{t("vision.desc")}</p>
              </div>
            </div>
            <div className="visionMissionWrapper">
              <div style={{
                paddingTop: '5px'
              }}>
                <div className="dotsMisionVision"></div>
              </div>

              <div
                style={{
                  marginLeft: "20px",
                }}
              >
                <h4>{t("mission.title")}</h4>
                <p>{t("mission.desc")}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex flex-column align-items-center justify-content-center fishermanBg rounded h-100 py-5 px-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionAndMission;
