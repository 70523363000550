import React from "react";

interface IModalVideo {
  isShow: boolean;
}

const ModalVideo: React.FC<IModalVideo> = ({ isShow }) => {
  return (
    <div
      className="modal fade"
      id="videoModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={{
        zIndex: 99999,
      }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="embed-responsive embed-responsive-16by9">
              {/* <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/2B_q4qaLQaw"
                  id="video"
                  allow={isShow ? "autoplay": ""}
                ></iframe> */}
              <video
                width="1920"
                height="1080"
                controls
                preload="metadata"
                poster="https://agreevershost.my.id/assets/agreeseafood/image/additional%20image/Sea%20Cucumber%20Video.mp4"
              >
                <source
                  src="https://agreevershost.my.id/assets/agreeseafood/image/additional%20image/Sea%20Cucumber%20Video.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalVideo;
